$theme: 'refititBlue';
@import '~bulma';
@import '@stimcar/libs-uitoolkit/dist/react/bulma/sass/custom.scss';

div.dashed-border {
  border: 2px dashed var(--bulma-label-color);
}

div.kanbans-board {
  display: grid;
  grid-row-gap: 2px;
  grid-column-gap: 5px;

  .bandwidth {
    @extend .is-flex;
    @extend .is-align-items-center;
    @extend .has-text-centered;
    @extend .has-text-black;
    @extend .has-text-weight-bold;
    width: 100%;

    .bandwidth-label {
      width: 100%;
    }
  }

  .is-repair-order-slot {
    @extend .m-0;
    @extend .has-radius-normal;
    @extend .is-align-items-center;
    @extend .is-clipped;
    white-space: nowrap;
    text-overflow: ellipsis;

    button {
      @extend .has-text-dark;
      @extend .has-text-weight-medium;
      @extend .is-family-monospace;
    }
  }

  .is-available-slot {
    @extend .has-background-grey-light;
    @extend .has-radius-normal;
    border: 2px dashed var(--bulma-label-color);
  }
}
