$theme: 'refititBlue';
.predictions-container {
  width: 100%;
}

.score-indicator {
  width: 40px;
  height: 18px;
  margin-top: 4px;
  border-radius: 2px;
  border: 1.5px solid black;
}
