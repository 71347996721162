$theme: 'refititBlue';
.picture-input-camera-modal {
  .camera-container {
    width: 100vw;
    overflow: hidden;
    max-height: none;
  }
  .panorama-icon {
    height: 72px;
    width: 72px;
    i {
      font-size: 72px;
    }
  }
  .camera-stream {
    width: 100vw;
    height: 100vh;
    object-fit: fill;
  }
  .camera-stream-mask {
    left: 50%;
    bottom: 50%;
    max-height: 100vh;
    position: absolute;
    transform: translate(-50%, 50%);
  }
  .camera-picture-button {
    right: 4%;
    bottom: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    transform: translate(0%, 75%);
  }
  .camera-picture-button::before {
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid black;
    background-color: white;
  }
  .select-from-gallery-icon {
    right: 5%;
    bottom: 10%;
    position: absolute;
    transform: translate(0%, 75%);
  }
}
