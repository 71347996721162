$theme: 'refititBlue';
@use '@stimcar/libs-uitoolkit/dist/react/bulma/sass/colors.scss';

.defects-tab-component-left-panel {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
}

.button-has-defect {
  position: absolute;
  top: 10%;
  left: 70%;
  width: 10px;
  height: 10px;
  background-color: colors.$refitit-orange;
  border-radius: 50%;
}

.defect-marker {
  width: 20px;
  height: 20px;
  cursor: crosshair;
  border-radius: 50%;
  border: 1px solid black;
  background-color: colors.$refitit-orange !important;
}

.defects-tab-component-right-panel {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 70%;

  .defects-table {
    width: 100%;
  }
}

.highlighter-image-container {
  width: 600px;
  max-width: 600px;
  height: 450px;
  max-height: 450px;
}

.defect-highlighter {
  position: absolute;
  border: 2px solid colors.$refitit-orange !important;
  border-radius: 50%;
  background-color: transparent;
  pointer-events: none;
}
